import React, { useState, useEffect } from "react";
import ItemModal from "./ItemModal";
import SubCategoryMenu from "./SubCategoryMenu";
import useTranslations from "../../hooks/useTranslations";
import Header from "../Header";
import { Flex, Text, Link, Box } from "@chakra-ui/react";
import { X } from "lucide-react";
import { DateTime } from "luxon"; // Import Luxon for date and time manipulation

const MenuList = ({
  restaurantId,
  subCategories,
  menuItems,
  currentMainCategory,
  allLanguages,
  secondaryLanguages,
  handleLanguageChange,
  styles,
  tags,
  defaultLanguage,
  selectedLanguage,
  logo,
  menuUrl,
  extrasGroups,
}) => {
  const [uniqueTags, setUniqueTags] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const { translations } = useTranslations();
  const [filter, setFilter] = useState(null);

  // Utility function to check if a category is within its display range
  const isCategoryVisible = (category) => {
    // If displayDays or timeRange are not defined, assume category should be visible
    if (!category.displayDays || category.displayDays.length === 0) {
      console.log(`Category ${category.id} has no displayDays restrictions.`);
      return true;
    }

    const now = DateTime.local(); // Current time
    const currentDay = now.toFormat("EEEE"); // Day as a string like "Wednesday"
    const isDayVisible = category.displayDays.includes(currentDay);

    // If no timeRange is defined or if timeRange has null values, consider it as "all day"
    if (
      !category.timeRange ||
      !category.timeRange.start ||
      !category.timeRange.end
    ) {
      return isDayVisible;
    }

    // Parse and check the time range if timeRange is defined
    const [startHour, startMinute] = category.timeRange.start
      .split(":")
      .map(Number);
    const [endHour, endMinute] = category.timeRange.end.split(":").map(Number);

    const startTime = now.set({ hour: startHour, minute: startMinute });
    const endTime = now.set({ hour: endHour, minute: endMinute });

    const isTimeVisible = now >= startTime && now <= endTime;

    // Final visibility depends on both day and time checks
    const isVisible = isDayVisible && isTimeVisible;

    console.log(`Category ${category.id} final visibility: ${isVisible}`);
    return isVisible;
  };

  const subCategoriesWithItems = subCategories.filter((subCategory) =>
    menuItems.some(
      (item) =>
        item.categorieId === subCategory.id &&
        item.display &&
        item.display.includes("menu")
    )
  );

  const filteredSubCategories =
    subCategoriesWithItems.filter(isCategoryVisible);

  useEffect(() => {
    setFilter(null);
  }, [currentMainCategory]);

  const filteredItems = menuItems.filter((item) => {
    const hasShopDisplay = item.display && item.display.includes("menu");
    const matchesTagFilter = filter
      ? item.tags &&
        Array.isArray(item.tags) &&
        item.tags.some((tag) => tag.value === filter)
      : true;

    const isAvailable = item.availability !== false;

    return hasShopDisplay && matchesTagFilter && isAvailable;
  });

  const openModal = (item, category) => {
    setSelectedItem(item);
    setCurrentCategory(category);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const itemsByCategory = menuItems.reduce((acc, item) => {
    const { categorieId } = item;
    if (!acc[categorieId]) {
      acc[categorieId] = [];
    }
    acc[categorieId].push(item);
    return acc;
  }, {});

  useEffect(() => {
    // Filtrez les items pour inclure uniquement ceux dont le display inclut "menu"
    const filteredItemsForTags = menuItems.filter(
      (item) => item.display && item.display.includes("menu")
    );

    // Construisez l'ensemble des tags uniques à partir des items filtrés
    const tagsSet = new Set();
    filteredItemsForTags.forEach((item) => {
      if (item.tags && Array.isArray(item.tags)) {
        item.tags.forEach((tag) => tagsSet.add(tag.value));
      }
    });

    setUniqueTags(Array.from(tagsSet)); // Met à jour l'état des tags uniques
  }, [menuItems]);

  const tagButtons = uniqueTags.map((tagID) => {
    const correspondingTag = tags.find((t) => t.id === tagID);

    let tagName = "Tag inconnu";
    if (correspondingTag) {
      tagName =
        correspondingTag[`name_${selectedLanguage}`] ||
        correspondingTag[`name_${defaultLanguage.value}`] ||
        "Tag inconnu";
    }

    return (
      <button
        className={`${styles.filter} ${
          filter === tagID ? `${styles.active}` : ""
        }`}
        key={tagID}
        onClick={() => setFilter(filter === tagID ? null : tagID)}>
        <span>{tagName}</span>
        {filter === tagID && <X size={16} color="rgba(0, 0, 0, 0.6)" />}
      </button>
    );
  });

  const classNamesMapping = {
    cards: styles.cards,
  };

  const renderTagLabel = (tagID) => {
    const correspondingTag = tags.find((tag) => tag.id === tagID);
    if (!correspondingTag) return "Tag inconnu";
    // Utiliser la traduction selon la langue sélectionnée, ou retomber sur une langue par défaut
    const tagName =
      correspondingTag[`name_${selectedLanguage}`] ||
      correspondingTag[`name_${defaultLanguage.value}`] ||
      "Tag inconnu";
    return tagName;
  };

  console.log("currentMainCategory", currentMainCategory);

  return (
    <>
      <Header
        restaurantId={restaurantId}
        currentMainCategory={currentMainCategory}
        logo={logo}
        menuUrl={menuUrl}
        styles={styles}
        selectedLanguage={selectedLanguage}
        defaultLanguage={defaultLanguage}
        secondaryLanguages={secondaryLanguages}
        handleLanguageChange={handleLanguageChange}
      />

      <SubCategoryMenu
        subCategories={filteredSubCategories}
        currentMainCategory={currentMainCategory}
        itemsByCategory={itemsByCategory}
        styles={styles}
        defaultLanguage={defaultLanguage}
        selectedLanguage={selectedLanguage}
      />

      <Box as="main" className={styles.wrapper} flex="1 1 auto">
        <Flex as="section" className={styles.sectionMenu} flex={1} w={"100%"}>
          <Box
            className={styles.container}
            flex={1}
            justifyContent={"space-between"}>
            <Flex>
              {tagButtons && tagButtons.length > 0 && (
                <div className={styles.filtersWrapper}>
                  <div className={styles.button}>
                    <span className={styles.icon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6ZM5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12ZM8 18C8 17.4477 8.44772 17 9 17H15C15.5523 17 16 17.4477 16 18C16 18.5523 15.5523 19 15 19H9C8.44772 19 8 18.5523 8 18Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    {translations.filters[selectedLanguage]}
                  </div>
                  <div className={styles.filters}>{tagButtons}</div>
                </div>
              )}

              <div className={styles.categories}>
                {filteredSubCategories
                  .filter((cat) => cat.id !== "uncategorized")
                  .map((category) => {
                    const categoryItems = filteredItems.filter(
                      (item) => item.categorieId === category.id
                    );

                    const categoryStyleClass =
                      category.style &&
                      classNamesMapping[category.style.toLowerCase()]
                        ? classNamesMapping[category.style.toLowerCase()]
                        : "";

                    if (!categoryItems.length) return null;
                    return (
                      <div
                        key={category.id}
                        id={category.id}
                        className={styles.category}>
                        <Flex direction={"column"} gap={2}>
                          <h2>
                            {category[`name_${selectedLanguage}`] ||
                              category[`name_${defaultLanguage.value}`]}
                            <div className={styles.categoryPrice}>
                              {category.formula && category.formulaPrice && (
                                <>
                                  {category.formulaPrice
                                    .toFixed(2)
                                    .replace(".", ",")}
                                  <span>€</span>
                                </>
                              )}
                            </div>
                          </h2>

                          <Text color="var(--text-color)" fontSize="16px">
                            {category[
                              `description_${defaultLanguage.value}`
                            ] && (
                              <>
                                {category[`description_${selectedLanguage}`] ||
                                  category[
                                    `description_${defaultLanguage.value}`
                                  ]}
                              </>
                            )}
                          </Text>
                        </Flex>

                        <div
                          className={`${styles.items} ${categoryStyleClass}`}>
                          {categoryItems.map((item) => (
                            <div
                              className={styles.item}
                              key={item.id}
                              onClick={() => openModal(item, category)}>
                              <div className={styles.itemInfo}>
                                <div className={styles.head}>
                                  <div className={styles.itemName}>
                                    <h4>
                                      {item[`name_${selectedLanguage}`] ||
                                        item[`name_${defaultLanguage.value}`]}
                                    </h4>
                                  </div>
                                  {category.style !== "Cards" &&
                                    item[
                                      `description_${defaultLanguage.value}`
                                    ] && (
                                      <Text fontSize=".95em">
                                        {item[
                                          `description_${selectedLanguage}`
                                        ] ||
                                          item[
                                            `description_${defaultLanguage.value}`
                                          ]}
                                      </Text>
                                    )}
                                </div>

                                <div className={styles.priceRow}>
                                  <Flex
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    w={"100%"}>
                                    
                                    
                                    {!category.formula && currentMainCategory.showPrices && (
                                        
                                        <div className={styles.priceInfo}>
                                          {item.type === "variable" ? (
                                            <div className={styles.variations}>
                                              <>
                                                {item.variations.length > 3 ? (
                                                  <div
                                                    className={
                                                      styles.variation
                                                    }>
                                                    <span
                                                      className={
                                                        styles.variationName
                                                      }>
                                                      From
                                                    </span>
                                                    <span
                                                      className={styles.price}>
                                                      {Math.min(
                                                        ...item.variations.map(
                                                          (variation) =>
                                                            parseFloat(
                                                              variation.price.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )
                                                        )
                                                      ).toLocaleString(
                                                        "fr-FR",
                                                        {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                      €
                                                    </span>
                                                  </div>
                                                ) : (
                                                  item.variations.map(
                                                    (variation, index) => (
                                                      <div
                                                        className={
                                                          styles.variation
                                                        }
                                                        key={index}>
                                                        <span
                                                          className={
                                                            styles.variationName
                                                          }>
                                                          {variation.name}
                                                        </span>
                                                        <span
                                                          className={
                                                            styles.price
                                                          }>
                                                          {variation.price}€
                                                        </span>
                                                      </div>
                                                    )
                                                  )
                                                )}
                                              </>
                                            </div>
                                          ) : (
                                            item.price && (
                                              <span className={styles.price}>
                                                {item.price}€
                                              </span>
                                            )
                                          )}
                                        </div>
                                        
                                      )}

                                    <div className={styles.tags}>
                                      <>
                                        {category.style !== "Cards" &&
                                          (item[`note_${selectedLanguage}`] ||
                                            item[
                                              `note_${defaultLanguage.value}`
                                            ]) && (
                                            <div className={styles.note}>
                                              {item[
                                                `note_${selectedLanguage}`
                                              ] ||
                                                item[
                                                  `note_${defaultLanguage.value}`
                                                ]}
                                            </div>
                                          )}
                                        {item.tags
                                          .slice(0, 2)
                                          .map((tag, index) => (
                                            <span
                                              key={index}
                                              className={styles.tag}>
                                              {renderTagLabel(tag.value)}
                                            </span>
                                          ))}
                                        {item.tags.length > 2 && (
                                          <span className={styles.tag}>
                                            +{item.tags.length - 1}
                                          </span>
                                        )}
                                      </>
                                    </div>
                                  </Flex>
                                </div>
                              </div>

                              {(currentMainCategory?.imageDisplay ||
                                category.style === "Cards") &&
                                item.photo && (
                                  <div className={styles.itemImage}>
                                    <img
                                      src={item.photo}
                                      alt={
                                        item[`name_${selectedLanguage}`] ||
                                        item[`name_${defaultLanguage.value}`]
                                      }
                                    />
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Flex>
            <Flex
              pb="24px"
              fontSize="11px"
              direction="row"
              gap="4px"
              justifyContent={"center"}>
              <Link
                color="var(--grey400)"
                href="https://gudden.app"
                target="_blank"
                rel="noopener noreferrer">
                Powered by Gudden.app
              </Link>
            </Flex>
          </Box>
        </Flex>
      </Box>
      {isModalOpen && selectedItem && (
        <ItemModal
          restaurantId={restaurantId}
          item={selectedItem}
          isOpen={openModal}
          onClose={closeModal}
          currentMainCategory={currentMainCategory}
          category={currentCategory}
          styles={styles}
          selectedLanguage={selectedLanguage}
          defaultLanguage={defaultLanguage}
          allLanguages={allLanguages}
          extrasGroups={extrasGroups}
        />
      )}
    </>
  );
};

export default MenuList;
